import React, { lazy, Suspense } from 'react';

import { Route, Switch, useRouteMatch, useLocation } from 'react-router-dom';
import { AdminSettingsRoute } from './lib/components/Routes/ApplicationManagementRoute';
import { Loader } from './lib/components';
import { EquityRoute } from 'lib/components/Routes/EquityRoute';

const MutualFund = lazy(() => import('./modules/MutualFundsModule/index'));
const Equity = lazy(() => import('./modules/Equity/EquityLayout'));
const Bond = lazy(() => import('./modules/Bond/Layout'));
const HolderDividendAndTransactions = lazy(() =>
  import('./modules/Equity/Holders/ViewDividendAndTransactions'),
);
const Dashboard = lazy(() => import('./modules/Dashboard/Dashboard'));
const AdminSettings = lazy(() => import('./modules/AdminSettings'));
const Management = lazy(() => import('./modules/Management/Index'));
const Offer = lazy(() => import('./modules/Equity/Offer/index'));

const Routes = () => {
  const { pathname } = useLocation();
  let { path } = useRouteMatch();

  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <AdminSettingsRoute
          bgType="primary"
          path={`${path}/admin-settings/:id`}
          component={AdminSettings}
        />
        <EquityRoute path={`${path}/Equity`} exact component={Equity} />
        <EquityRoute path={`${path}/Equity/:id`} component={Equity} />
        <EquityRoute path={`${path}/Equity/:id/:actionId`} component={Equity} />
        <EquityRoute
          path={`${path}/Equity/:id/:actionId/:secondActionId`}
          component={Equity}
        />
        <EquityRoute path={`${path}/bond`} exact component={Bond} />
        <EquityRoute path={`${path}/bond/:id`} exact component={Bond} />
        <Route exact path={`${path}/home`} component={Dashboard} />
        <Route path={`${path}/offer/:id`} component={Offer} />
        <Route exact path={`${path}/management`} component={Management} />
        <Route
          exact
          path={`${path}/dividendandtransaction`}
          component={HolderDividendAndTransactions}
          isLoggedIn={false}
        />
        <Route path={`${path}/mutualfund/:id`} component={MutualFund} />
        <Route path={`${path}/management/profileSuccess`} />
        <Route component={Dashboard} />
      </Switch>
    </Suspense>
  );
};

export default Routes;
