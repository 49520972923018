import jwt_decode from 'jwt-decode';

export const formatActioName = (param) => {
  const formattedAction = param.toUpperCase().trim().split(' ').join('_');
  return formattedAction;
};

export const getTokenInfo = (token) => {
  const decoded = jwt_decode(token);

  return decoded;
};

export const getTenantId = () => {
  const state = JSON.parse(
    window.localStorage.getItem('greenpole_redux_state'),
  );
  const result = getTokenInfo(state?.auth?.token);

  return result.tenant_id;
};

export const getUser = () => {
  const state = JSON.parse(
    window.localStorage.getItem('greenpole_redux_state'),
  );
  const result = getTokenInfo(state?.auth?.token);

  return result;
};

export const getCode = (currency) => {
  const result = currency.split(' ');
  const lastIndex = result[result.length - 1];

  return lastIndex.substring(1, lastIndex.length - 1);
};

export const formatUserDate = (dateInQuestion) => {
  if (dateInQuestion) {
    const result = dateInQuestion.split('T');
    return result[0];
  }

  return '';
};

export const formatCalenderDate = (dateInQuestion) => {
  return dateInQuestion.replace(/-/g, '/').split('/').reverse().join('/');
};

// format date
export const formatDate = (d) => {
  let month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};
