import React, { useEffect } from 'react';
import {
  Input,
  InputNumber,
  DatePicker,
  Upload,
  message,
  Form,
  Radio,
  Select,
} from 'antd';

import { InboxOutlined } from '@ant-design/icons';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './TextContainer.scss';
import { customStyle } from './customStyle';

// import nigeriaFlag from 'lib/assets/flags/NG.png';

const { Dragger } = Upload;
const { RangePicker } = DatePicker;
const { Option } = Select;
// const prefixSelector = (
//   <Form.Item
//   // name="prefix"
//   noStyle>
//    <img src={nigeriaFlag} alt="flag" style={{width: '45px',}}  />
//     {/* <Select
//       style={{
//         border: 0,
//       }}
//       value="+234"
//       style={{ width: 100 }}>
//       <Option value="234">+234</Option>
//     </Select> */}
//   </Form.Item>
// );

const TextContainer = ({
  type,
  name,
  rules,
  required,
  options,
  label,
  placeholder,
  value,
  disabled,
  onChange,
  onBlur,
  defaultValue,
  inputStyle,
  uploadProps,
  multiple,
  accept,
  beforeUpload,
  onDownload,
  onSearch,
  isLoading = false,
  dateFormat,
  mode,
  allowClear,
  onClear,
  className,
  readOnly,
  showSearch = true,
  disabledDate,
  ...formProps
}) => {
  // const props = {
  //   name: name,
  //   multiple: false,
  //   accept: options,
  //   // action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
  //   onChange(info) {
  //     const { status } = info.file;
  //     if (status !== 'uploading') {
  //     }
  //     if (status === 'done') {
  //       message.success(`${info.file.name} file uploaded successfully.`);
  //     } else if (status === 'error') {
  //       message.error(
  //         `${info.file.name} file upload failed. Excel file required`,
  //       );
  //     }
  //   },
  // };

  const props = {
    accept,
    multiple: multiple || false,
    name: name,
    beforeUpload: (file) => {
      if (beforeUpload) beforeUpload(file);
      return false;
    },
    onChange(info) {
      // const { status } = info.file;
      if (onChange) onChange(info.fileList);
      // if (status?.toLowerCase() === 'removed') setUploadFile(null);
    },
  };

  const { TextArea } = Input;

  useEffect(() => {
    const style = document.createElement('style');
    document.head.appendChild(style);

    style.innerHTML = customStyle;

    return () => {
      style.innerHTML = '';
    };
  }, []);

  return (
    <Form.Item
      name={name}
      rules={rules}
      initialValue={value}
      required={required}
      {...formProps}
      className="label_font"
      label={label}>
      {type === 'select' ? (
        <Select
          bordered={false}
          allowClear={allowClear}
          mode={mode}
          showSearch={showSearch}
          onClear={onClear}
          loading={isLoading}
          onSearch={onSearch}
          disabled={disabled}
          placeholder={placeholder}
          style={inputStyle}
          optionFilterProp="children"
          onChange={onChange}
          onBlur={onBlur}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          className={`customized_input ${className}`}>
          {options.map((item) => (
            <Option
              key={item.value}
              className="customized_input"
              value={item.value}>
              {item.name}
            </Option>
          ))}
        </Select>
      ) : type === 'radio' ? (
        <Radio.Group value={value} onChange={onChange}>
          {options.map((item) => (
            <Radio key={item.name} value={item.value || item.name}>
              {item.name}
            </Radio>
          ))}
        </Radio.Group>
      ) : type === 'date' ? (
        <DatePicker
          allowClear={allowClear}
          onChange={onChange}
          onBlur={onBlur}
          disabledDate={disabledDate}
          disabled={disabled}
          className="customized_input"
          defaultValue={defaultValue}
          style={{ width: '100%' }}
          format={dateFormat}
          placeholder={placeholder}
        />
      ) : type === 'date_range' ? (
        <RangePicker
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          className="customized_input"
          defaultValue={defaultValue}
          style={{ width: '100%' }}
        />
      ) : type === 'upload' ? (
        <>
          {onDownload && onDownload !== null && (
            <div className="template-download-link">
              <a onClick={onDownload} target="_blank">
                Download Template
              </a>
            </div>
          )}
          <Dragger {...(uploadProps || props)}>
            <p className="ant-upload-hint">
              <InboxOutlined />
              Drag file here or <span className="browse">Browse</span>
            </p>
          </Dragger>
        </>
      ) : type === 'number' ? (
        <InputNumber
          style={{ width: '100%', color: 'black' }}
          className="customized_input"
          disabled={disabled}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
        />
      ) : type === 'phone' ? (
        <PhoneInput
          country="ng"
          value={value}
          disabled={disabled}
          className="customized_input"
          onChange={onChange}
          onBlur={onBlur}
        />
      ) : // <Input
      //   className="customized_input"
      //   addonBefore={prefixSelector}
      //   disabled={disabled}
      //   placeholder={placeholder}
      //   onChange={onChange}
      // />
      type.toLowerCase() === 'textarea' ? (
        <TextArea
          style={{
            background: '#f7f7f7 0% 0% no-repeat padding-box',
            // color: 'rgba(0, 0, 0, 0.3',
          }}
          placeholder={placeholder}
          rows={4}
        />
      ) : (
        <Input
          disabled={disabled}
          readOnly={readOnly}
          className="customized_input"
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          style={inputStyle}
        />
      )}
    </Form.Item>
  );
};

export default TextContainer;
